import React from "react";
import { withPrefix } from "gatsby";
import Layout from "../../components/Layout";
import diagram from "../../images/diagram.png";
import "../../styles/global.css";
import "../../styles/generic.css";

function TankFirePage() {
  return (
    <Layout>
      <main
        className="generic bg-image"
        style={{
          backgroundColor: "#2a2e35",
          backgroundImage: `url(${withPrefix("/firebg.jpg")}`,
        }}
      >
        <div className="generic__container">
          <article className="generic__content">
            <h1>Your risk is ours too</h1>
            <div className="generic__line" />
            <p className="generic__highlight">
              We offer exclusive consulting and engineering services to
              storage-tank operators to improve their ability to subdue
              large-scale fire incidents cost effectively.
            </p>
            <p>
              The shock of seeing an uncontrollable blaze ravaging your
              facility’s primary tanks, devouring the assets as well as your
              company’s reputation, is a trauma you should never have to
              experience.
            </p>
            <p>We are here to make sure you never do.</p>
            <p>
              SFPRD is redrawing the lines of what is possible in
              hydrocarbon-storage security.
            </p>
            <p>
              When disaster strikes and overwhelms your current fire-suppression
              capabilities: Do you have any option other than allowing your
              tanks to burn down completely?
            </p>
            <p>
              We have{" "}
              <a href="https://pifoam.ch" target="blank">
                the answer.
              </a>
            </p>
          </article>
          <img src={diagram} alt="chart about tank fires" />
        </div>
      </main>
    </Layout>
  );
}

export default TankFirePage;
